<script setup lang="ts">
const props = defineProps<{
    content: any;
}>();

const items = [
    {
        image: 'icon-wineglass.svg',
        text: 'Alt i drikkevarer og tilbehør',
    },
    {
        image: 'icon-truck.svg',
        text: 'Lynhurtig levering',
    },
    {
        image: 'icon-smiley.svg',
        text: '122.000 glade kunder',
    },
    {
        image: 'icon-pricetag.svg',
        text: 'Priser i verdensklasse',
    },
    {
        image: 'icon-flag.svg',
        text: '100% dansk virksomhed',
    },
];

const marqueeContainer = ref<HTMLElement|null>(null);
const marqueeContent = ref<HTMLElement|null>(null);
let animationFrameId: number|null = null;

const marqueeSpeed = 0.5;

const startMarquee = () => {
    if (!marqueeContainer.value || !marqueeContent.value) return;

    const contentWidth = marqueeContent.value.scrollWidth;

    let currentPosition = 0;

    const step = () => {
        if (!marqueeContent.value) return;

        currentPosition -= marqueeSpeed;
        if (Math.abs(currentPosition) >= contentWidth / 2) {
            currentPosition = 0;
        }
        marqueeContent.value.style.transform = `translateX(${currentPosition}px)`;
        animationFrameId = requestAnimationFrame(step);
    };

    step();
};

const stopMarquee = () => {
    if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = null;
    }
};

const checkScreenSize = () => {
    const isLargeScreen = window.matchMedia("(min-width: 1280px)").matches;
    if (isLargeScreen) {
        stopMarquee();
        marqueeContent.value.style.transform = 'none';
    } else {
        startMarquee();
    }
};

onMounted(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', checkScreenSize);
    stopMarquee();
});
</script>

<template>
<div ref="marqueeContainer" class="marquee-container container element-hardcoded-usps">
    <div ref="marqueeContent" class="marquee-content flex w-full justify-between">
        <div class="marquee-item flex flex-align-center" v-for="(item, index) in items" :key="'original-' + index">
            <NuxtImg :src="item.image" height="20" :alt="item.text" />
            <span class="text-[15px] md:text-[16px] ml-[12px]">{{ item.text }}</span>
        </div>
        <div class="marquee-item flex flex-align-center xl:hidden" v-for="(item, index) in items" :key="'duplicate-' + index">
            <NuxtImg :src="item.image" height="20" :alt="item.text" />
            <span class="text-[15px] md:text-[16px] ml-[12px]">{{ item.text }}</span>
        </div>
    </div>
</div>
</template>
<style scoped>
.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    .marquee-content {
        .marquee-item {
            margin-right: 40px;
        }
    }

    @media (min-width: 1280px) {
        overflow: visible;

        .marquee-content {
            transform: none !important;
            .marquee-item {
                margin-right: 0;
            }
        }
    }
}
</style>
